import { render, staticRenderFns } from "./FeesCreate.vue?vue&type=template&id=3ce074b8&scoped=true&"
import script from "./FeesCreate.vue?vue&type=script&lang=js&"
export * from "./FeesCreate.vue?vue&type=script&lang=js&"
import style0 from "./FeesCreate.vue?vue&type=style&index=0&id=3ce074b8&scoped=true&lang=css&"
import style1 from "./FeesCreate.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ce074b8",
  null
  
)

export default component.exports