<template>
  <b-card>
    <div class="text-right mb-1">
      <b-button size="sm" variant="warning" @click="goToHome"> <b-icon-arrow-left /> Regresar</b-button>
    </div>
    <ValidationObserver ref="createFees" v-slot="{ invalid }" >
      <b-form @submit.prevent="save">
        <b-row>
          <b-col md="3">
            <validation-provider name="tipo Busqueda" rules="required">
              <b-form-group label="Pool de renta">
                <b-form-select v-model="rentalPool" placeholder="Pool">
                  <option selected value="">Seleccione un Pool de renta</option>
                  <option
                    v-for="condo in rentalPoolFilter"
                    :key="condo.id"
                    :value="condo.id"
                  >
                    {{ condo.name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider name="tasas" rules="required">
              <b-form-group label="Tasas">
                <b-form-select v-model="fees" placeholder="tasas">
                  <option selected value="">Seleccione una tasa</option>
                  <option
                    v-for="feesTypeItem in feesType"
                    :key="feesTypeItem.id"
                    :value="feesTypeItem.id"
                  >
                    {{ feesTypeItem.name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider name="tipo de valor" rules="required">
              <b-form-group label="Tipo de valor">
                <b-form-select v-model="valueType" >
                  <option selected value="">Seleccione tipo de valor</option>
                  <option
                    v-for="valueTypeItem in valueTypeList"
                    :key="valueTypeItem.id"
                    :value="valueTypeItem.id"
                  >
                    {{ valueTypeItem.name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider name="estimateType" rules="required">
              <b-form-group label="Tipo de Calculo">
                <b-form-select v-model="estimateType" >
                  <option selected value="">Seleccione tipo de calculo</option>
                  <option
                    v-for="estimateTypeItem in estimateTypeList"
                    :key="estimateTypeItem.id"
                    :value="estimateTypeItem.id"
                  >
                    {{ estimateTypeItem.name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="2" lg="3">
            <b-form-group label="Año">
              <b-form-select v-model="year" :options="validYears" />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Mes">
              <b-form-select v-model="month" :options="monthList" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <ValidationProvider rules="required" name="Valor">
              <b-form-group label="Valor" slot-scope="{ valid, errors }">
                <b-form-input
                  class="form-control"
                  type="text"
                  v-model="valor"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="3">
            <div class="float-left pt-1">
              <b-form-checkbox
                v-model="isActive"
                :value="true"
                class="custom-control-success"
              >
                Activo
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
  
        <b-row v-can="'fivesclub_profit_save_fee'">
          <b-col>
            <div class="float-right">
              <b-button
                type="submit"
                class="btn-block"
                variant="primary"
                :disabled="invalid || isSavingBlackout"
              > <b-spinner small v-if="isSavingBlackout"/> Guardar
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { showAlertMessage } from '@/helpers/helpers'
import { estimateTypeList,valueTypeList, monthList} from "@/data/data";

export default {
  data() {
    return {
      isActive: true,
      rentalPool:'',
      fees:'',
      valor:null,
      valueTypeList,
      estimateTypeList,
      estimateType:'',
      valueType:'',
      isSavingBlackout: false,
      monthList,
      datenow: new Date(),
      year: new Date().getFullYear(),
      month: 1,
    }
  },
  computed: {
    ...mapState('auth',['user']),
    ...mapState("fivesClubProfit", ['rentalPoolFilter','feesType']),
    validYears() {
      const years = []
      const currentYear = new Date().getFullYear()
      for (let i = currentYear - 2; i < currentYear + 1; i++) {
        years.push(i + 1)
      }
      return years
    },
  },
  methods: {
    ...mapMutations('fivesClubProfit', ['setFees']),
    ...mapActions('fivesClubProfit', ['saveFees','fetchFeeSettings']),
    async save(){
      this.isSavingBlackout = true
      const payload = {
        createdBy: this.user.idUser,
        fees:this.fees,
        rentalPool : this.rentalPool,
        valueType:this.valueType,
        estimateType:this.estimateType,
        value:this.valor,
        isActive: Boolean(this.isActive),
        year: this.year,
        month: this.month,
      }

      const { status, message } = await this.saveFees( payload ) // saving in backend

      if(status){
        showAlertMessage( 'Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right')
        //vuelvo a pedir la info del back
        this.goToHome({reload: true})
      }
      this.isSavingBlackout = false
    },
    goToHome(params){
      this.$router.push({ name: 'fees-list', params })
    },
  },
};
</script>
<style scoped>
.custom-control {
  margin-block-start: 1.5rem;
}
.btn-add{
  margin-block-start: 1.5rem;
}
.btn-borrar{
  margin-block-start: 1.5rem;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>